html {
    font-family: 'Kosugi Maru', sans-serif;
    color: #a88d74;
    background: url('/assets/background.png') no-repeat fixed;
    background-size: cover;
}

h2 {
    font-size: 20px;
}

h3 {
    font-size: 18px;
}

h4 {
    font-size: 16px;
}

div, button {
    outline: 0;
}
